<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
  >
    <el-form :model="formData" ref="form" label-width="130px" size="mini" v-loading="loading">
      <el-form-item label="抵用卷标题">
        <div> {{formData.productNumber}} </div>
      </el-form-item>
      <el-form-item label="抵用卷内容">
        <div> {{formData.productName}} </div>
      </el-form-item>
      <el-form-item label="抵用金额">
        <div> {{formData.productSize}} </div>
      </el-form-item>
      <el-form-item label="使用范围">
        <div> {{formData.designerName}} </div>
      </el-form-item>
      <el-form-item label="使用门槛">
        <div> {{formData.divide}} </div>
      </el-form-item>
      <el-form-item label="有效期">
        <div> {{formData.divide}} </div>
      </el-form-item>
      <el-form-item label="发放数量">
        <div> {{formData.divide}} </div>
      </el-form-item>
      <el-form-item label="创建时间">
        <div> {{formData.divide}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >取消</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {reelById} from '@/api/backendall/goods'

export default {
  name: 'preview',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal,oldval){
        newVal && this.getReelById(newVal)
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
    },
    async getReelById(id){
      this.loading = true
      const res = await reelById(id)
      this.formData = res.data
      this.loading = false
    }
  },
}
</script>

<style>

</style>