<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="onClickAction('updateVisible')"> 新增 </el-button>
    </div>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="productNumber" label="抵用卷标题">
          <gl-list-search v-model.trim="mFormData.productNumber" placeholder="抵用卷标题" @enter="mGetList"></gl-list-search>
        </el-form-item>
        <el-form-item prop="money" label="抵用金额">
          <gl-list-search v-model.trim="mFormData.money" placeholder="抵用金额" @enter="mGetList"></gl-list-search>
        </el-form-item>
         <el-form-item prop="startTime" label="创建时间">
          <gl-date-picker 
            v-model="mFormData.dateTime" 
            :startTime.sync="mFormData.startTime" 
            :endTime.sync="mFormData.endTime">
          </gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="productNumber" label="抵用卷标题"></el-table-column>
        <el-table-column prop="productName" label="抵用金额"></el-table-column>
        <el-table-column prop="designerName" label="使用范围"></el-table-column>
        <el-table-column prop="divide" label="使用门槛"></el-table-column>
        <el-table-column prop="divide" label="有效期"></el-table-column>
        <el-table-column prop="divide" label="发放数量"></el-table-column>
        <el-table-column prop="divide" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="210px">
          <template slot-scope="{row}">
            <el-button type="text" @click="onClickAction('updateVisible', row)"> 编辑 </el-button>
            <el-button type="text" @click="mPublish"> 上架 </el-button>
            <el-button type="text" @click="mRevoke"> 下架 </el-button>
            <el-button type="text" @click="mDelete(row.productId)"> 删除 </el-button>
            <el-button type="text" @click="onClickAction('previewVisible', row)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <update :visible.sync="updateVisible" :itemId="itemId"/>
    <preview :visible.sync="previewVisible" :itemId="itemId"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import {reelList, reelPublish, reelRevoke, reelDelete} from '@/api/backendall/goods'
import update from './update.vue'
import preview from './preview.vue'
export default {
  components: {update,preview},
  mixins: [ListMixin],
  data(){
    return{
      updateVisible: false,
      previewVisible: false,
      itemId: ''
    }
  },
  mounted(){
    this.mGetListFun = reelList
    this.mPublishFun = reelPublish
    this.mRevokeFun = reelRevoke
    this.mDeleteFun = reelDelete
    // this.mGetList()
  },
  methods: {
    onClickAction(name, item){
      this.itemId = item?.id || '' 
      this[name] = true
    }
  },
}
</script>

<style>

</style>