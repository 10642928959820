
import request from '@/plugins/request'


//商品管理-商品管理 列表
export function goodsList(params = {}){
  params.isManage = '1'
  return request({
    url: `/api/goods/item/getItemList`,
    method: 'POST',
    data: params,
  })
}
//商品管理-商品管理-详情:::未使用
export function goodsById(itemId = ''){
  if(!itemId) return new Error('id is undefined') 
  let params = {
    itemId: itemId
  }
  return request({
    url: '/api/goods/item/getItem',
    method: 'GET',
    params,
  })
}
export function setDiscount(params = {}){  //设置折扣
  return request({
    url: '/api/goods/item/discount',
    method: 'GET',
    params,
  })
}
//商品管理-商品管理-审核
export function goodsAudit(data = {}){
  return request({
    url: `/api/goods/item/approvalItem`,
    method: 'POST',
    data,
  })
}
//商品管理-商品管理-发布
export function publishAndRevoke(itemId){
  return request({
    url: '/api/goods/item/putItem'+'?itemId='+itemId,
    method: 'GET',
  })
}
//商品管理-商品管理-下架
// export function goodsRevoke(itemId){
//   return request({
//     url: '/api/goods/item/putItem'+'?itemId='+itemId,
//     method: 'GET',
//   })
// }
//商品管理-商品管理-修改:::未使用
export function goodsUpdate(data = {}){
  return request({
    url: 'api/goods/item/updateItem',
    method: 'POST',
    data: data,
  })
}

// 商品管理-商品管理-设置运费
export function setFreight(data = {}){
  return request({
    url: '/api/goods/item/approvalItem',
    method: 'POST',
    data: data,
  })
}
// 商品管理-商品挂你-运费详情回显
// export function getFreight(itemId){
//   return request({
//     url: '/api/goods/item/getItem'+'?itemId='+itemId,
//     method: 'GET',
//   })
// }




// 分类管理-商品分类列表查询
export function categoryList(params = {}){
  return request({
    url: `/api/goods/category/getCategoryList`,
    method: 'GET',
    params: params,
  })
}

// 分类管理-商品分类 新增商品类目
export function addCategory(data = {}) {
  return request({
    url: '/api/goods/category/addCategory',
    method: 'POST',
    data,
  })
}

// 分类管理-商品分类 编辑商品类目
export function updateCategory(data = {}){
  return request({
    url: '/api/goods/category/updateCategory',
    method: 'POST',
    data,
  })
}

// 分类管理-商品分类 查询商品类目
export function getCategory(params = {}){
  return request({
    url: '/api/goods/category/getCategory',
    method: 'GET',
    params,
  })
}

// 分类管理-商品分类 删除商品类目
export function deleteCategory(id){
  if(!id) return new Error('id is undefined') 
  return request({
    url: '/api/goods/category/deleteCategory'+ '?categoryId='+id,
    method: 'GET',
  })
}


// 商品管理 - 产品编号 列表
export function productList(data = {}){
  return request({
    url: '/api/goods/product/getProductList',
    method: 'POST',
    data,
  })
}

// 商品管理 - 产品编号 新增
export function productAdd(data = {}){
  return request({
    url: '/api/goods/product/addProduct',
    method: 'POST',
    data,
  })
}

// 商品管理 - 产品编号 修改
export function productUpdate(data = {}){
  return request({
    url: '/api/goods/product/updateProduct',
    method: 'POST',
    data,
  })
}

// 商品管理 - 产品编号 查询详情
export function productById(id){
  let params = {
    productId: id,
  }
  return request({
    url: '/api/goods/product/getProduct',
    method: 'GET',
    params: params,
  })
}


// 商品管理 - 产品编号 删除
export function productDelete(id = ''){
  if(!id) return new Error('id is undefined') 
  return request({
    url: '/api/goods/product/deleteProduct'+ '?productId='+id,
    method: 'GET',
  })
}








// 商品管理 - 抵用卷管理 列表
export function reelList(params = {}){
  return request({
    url: '/api',
    method: 'GET',
    params,
  })
}
// 商品管理 - 抵用卷管理 新增
export function reelAdd(data){
  return request({
    url: '/api',
    method: 'POST',
    data,
  })
}

// 商品管理 - 抵用卷管理 详情
export function reelById(id){
  if(!id) return new Error('id is undefined') 
  let params = {
    id: id,
  }
  return request({
    url: '/api',
    method: 'GET',
    params,
  })
}

// 商品管理 - 抵用卷管理 修改
export function reelUpdate(data){
  return request({
    url: '/api',
    method: 'POST',
    data,
  })
}
// 商品管理 - 抵用卷管理 上架
export function reelPublish(id){
  return request({
    url: '/api',
    method: '',
  })
}

// 商品管理 - 抵用卷管理 下架
export function reelRevoke(id){
  return request({
    url: '/api',
    method: '',
  })
}
// 商品管理 - 抵用卷管理 删除
export function reelDelete(id){
  return request({
    url: '/api',
    method: ''
  })
}





export default {
  //商品管理
  goodsList,
  goodsById,
  setDiscount,
  goodsAudit,
  publishAndRevoke,
  //分类管理
  categoryList,
  addCategory,
  updateCategory,
  getCategory,
  deleteCategory,
  //产品编号
  productList,
  productAdd,
  productUpdate,
  productDelete,
  //抵用卷管理
  reelList,
  reelAdd,
  reelById,
  reelUpdate,
  reelPublish,
  reelRevoke,
  reelDelete,
}