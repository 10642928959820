<template>
  <el-dialog 
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="130px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="productNumber" label="抵用卷标题" verify>
          <el-input v-model="formData.productNumber"></el-input>
        </el-form-item>
        <el-form-item prop="productName" label="抵用卷内容" verify>
          <el-input v-model="formData.productName"></el-input>
        </el-form-item>
        <el-form-item prop="productName" label="抵用金额" verify>
          <el-input v-model="formData.productSize"></el-input>
        </el-form-item>
        <el-form-item prop="designerName" label="使用范围" verify>
          <el-input v-model="formData.designerName"></el-input>
        </el-form-item>
        <el-form-item prop="divide" label="使用门槛" verify>
          <el-input v-model="formData.divide"></el-input>
        </el-form-item>
        <el-form-item prop="divide" label="有效期" verify>
          <el-input v-model="formData.divide"></el-input>
        </el-form-item>
        <el-form-item prop="divide" label="发放数量" verify>
          <el-input v-model="formData.divide"></el-input>
        </el-form-item>
        <el-form-item prop="createTime" label="创建时间" verify>
          <!-- value-format="yyyy-MM-dd HH:mm:ss" -->
          <el-date-picker
            v-model="formData.createTime"
            type="date"
            value-format="yyyy-MM-dd"  
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {reelAdd, reelUpdate, reelById} from '@/api/backendall/goods'
export default {
  name: 'update',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.getReelById(newVal)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSubmit(){
      console.log('formData:', this.formData)
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        let res = null
        if(this.itemId){
          res = await reelUpdate(this.formData)
        }else {
          res = await reelAdd(this.formData)
        }
        this.loading = false
        this.$message.success('操作成功')
        this.$emit('reload')
        this.handleClose()
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
    async getReelById(id){
      try{
        this.loading = true
        const res = await reelById(id)
        this.formData = {
          productId: res.data.productId,
          productNumber: res.data.productNumber,
          productName: res.data.productName,
          productSize: res.data.productSize,
          designerName: res.data.designerName,
          divide: res.data.divide,
        }
        this.loading = false
      }catch(e){
        this.loading = false
      }
    }
  }
}
</script>

<style>

</style>